.customRadio {
  .checkmark {
    display: none;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    & + .checkmark {
      display: inline-block;
      border: 1px solid #bdc3d4;
      padding: 4px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      box-sizing: border-box;
      margin: 0 15px 0 0;
      position: relative;
      vertical-align: middle;

      &.disabled {
        opacity: 0.15;
      }

      &::after {
        content: '';
        background: rgba(0, 148, 255, 0);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }
    &:checked + .checkmark::after {
      background: #040d17;
    }
  }
}
