.reqLanguage-btn {
    padding: 0 !important;
    color: #040d17 !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    border: none !important;
    background-color: transparent !important;
}

.reqLanguage-btn>span {
    padding: 0;
    margin: 2px 0;
    width: auto;
}

.reqLanguage-btn>span span:last-child {
    color: #040d17;
    font-size: 15px;
}

.reqLanguage-btn:after {
    display: inline-block;
    border-top: 5px solid #999 !important;
    margin-right: 7px;
}

.reqLanguage-btn_disabled:after {
    opacity: 0.35 !important;
}

.reqLanguage-btn[aria-expanded="true"]:after {
    border-bottom: 5px solid #999 !important;
    border-top: none !important;
}