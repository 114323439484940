.chooseLicenseWindow {
  .modal-dialog {
    max-width: 640px;
  }

  .modal-body {
    padding-top: 35px;

    .license-description {
      color: #040d1780;
      margin: 0px 0 5px 34px;
    }
  }
}