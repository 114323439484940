.photo-ch {
  background-color: transparent;
  color: #040D17 !important;
  border: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.12px;
  display: block;
  margin: 14px 0;
  line-height: 1.25;

  &:hover {
    color: #040D17 !important;
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.show-logo-header {
  color: #040D17;
  font-size: 14px;
  font-weight: 500;
}

.checkboxes-container {
  margin-bottom: 40px;

  .remem-checkbox {
    margin-bottom: 0;
    margin-top: 10px;
    color: #040D1780 !important;
    font-size: 14px;
    font-weight: 500;
  }

  .m-checkbox.m-checkbox--light>input:checked~span {
    border: 1px solid #040D17 !important;
  }
}

.data_SMID_container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 20px;
  color: #040D17;
  opacity: 0.5;
}

.profile-edit-prealoder-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group.m-form__group.input-field {
  .flag-select__btn::after {
    margin-right: 6px !important;
    margin-bottom: 12px !important;
  }

  [name="adminNote"],
  [name="note"] {
    color: #040D17;
    -webkit-text-fill-color: #040D17;

    &::-webkit-scrollbar {
      width: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #040D171a;
      outline: 1px solid #040D171a;
    }
  }

  &.input-field-street {
    margin-top: 1px !important;
    margin-bottom: 3px !important;
  }
}

.settings-user-details.m-form__group.input-field.input-field-for-ch-pass {
  margin-bottom: 26px !important;
}

.form-group.m-form__group.input-field.input-filed-flags-select {
  margin-bottom: 27px !important;
}

.Select-arrow-zone {
  right: -4px;
}

.flag-select__option.flag-select__option--placeholder {

  .flag-select__option__icon,
  .flag-select__option__label {
    margin-bottom: 3px;
  }
}

.link-for-logo {
  max-width: 138px !important;

  i {
    font-size: 14px;
    margin-bottom: 1px;
  }
}

.role-type {
  label {
    cursor: pointer !important;
  }
}

.payment-method-label,
.billing-label {
  font-size: 16px;
  color: #040D17 !important;
  letter-spacing: 0.48px;
  font-weight: 500;
}

.payment-method-text {
  @extend .payment-method-label;
  font-size: 14px;
  font-weight: 400;
}