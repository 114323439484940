#monitoring_expander_filter-reg {
    margin-left: 5px;
    color: #040D1780;
    border-color: #040D171A;
    background-color: #040D171A;
    width: 250px;
    height: 38px;
    padding: .65rem 1rem;
}

#monitoring_expander_filter-reg option {
    color: #040D17;
}

#pvisntaller_filter-reg,
#assign_filter-reg,
#status_filter-reg {
    color: #9699A2;
    border: 0;
}

.custom-search-container {
    width: 100%;
    max-width: 530px !important;
    margin: 0 !important;
}

.searchBox {
    margin-top: 0 !important;
}

.apply-monitoring-container {
    background-color: #fff;
    color: #040D17;
    padding: 0 15px 15px;
    align-items: end;
}

.main-filter-monitoring-container {
    color: #8F939B;
}

.btn-monitoring {
    background-color: var(--primary-color);
    color: #040D17;
    border: 1px solid var(--primary-color);
    width: 110px;
    margin-left: 5px;
    font-weight: 500;
    padding: 10px 5px !important;
    &:hover {
        background-color: #F2D155;
        border-color: #F2D155
    }
}

.monitoring-tool-tip {
    font-size: 16px;
}

.nav-monitoring {
    height: 35px;
    margin-top: -15px;
    padding-left: 0;
}

.input-note {
    width: 430px;
    height: 100px;
    margin: 20px;
    padding: 7px 15px;
    color: #040D17;
    border: 1px solid #040D171A;
    background-color: transparent;
}

#pvisntaller_filter-reg_input:focus,
#assign_filter-reg_input:focus,
#status_filter-reg_input:focus {
    border: none;
}

.table-row {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.search-field {
    width: 100%;
    max-width: 530px;
}

div .optionListContainer {
    z-index: 20;
}

ul.optionContainer li {
    color: #040D17;
}

li input[type="checkbox"] {
    background-color: #40424D;
}

ul.optionContainer li:first-child {
    background-color: transparent;
}

ul.optionContainer li:hover {
    background-color: #040D171A;
    color: #040D17;
}

.note-edit-prealoder-container {
    height: 236px;
    display: flex;
    align-items: center;
}

.number-column {
    margin-left: 12px;
    width: 15px;
}

.device-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 40%;
    margin-left: 12px;
}

.signal-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12.5%;
    max-width: 12.5%;
}

.error-date-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
}

.error-status-column {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 13%;
    max-width: 13%;
}

.assign-container {
    width: 30%;
    padding-left: 1%
}

.priority-container {
    width: 30%
}

.status-container {
    width: 30%
}

.monitoring-top-bar i.la-info-circle {
    margin-right: 30px;
}

.button-container {
    max-width: 10%;
    margin-left: 20px;
}

.table-row-details-td.undertable-note {
    overflow-wrap: break-word;
}

.monitoring-switch {
    margin-right: 15px;
}

.switch-container,
.custom-gap {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.resetBtn {
    display: flex;
    align-items: flex-end;
}

.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-disabled {
    opacity: 0.5;
}

.monitoringSignalColumn {
    span {
        margin-left: 15px;
    }
}

@media only screen and (max-width: 1100px) {
    .assign-container {
        width: 70%;
        padding-left: 1%
    }

    .priority-container {
        width: 70%
    }

    .status-container {
        width: 70%
    }

    .button-container {
        max-width: 30%;
    }
}