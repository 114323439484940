.batteryModeModal {
  .modalContainer {
    .modal-dialog {
      min-width: 570px;

      .modal-body  {
        padding: 36px 50px;

        .description-wrapper {
          margin-bottom: 36px;
        }

        .field-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .MuiFormControl-root.MuiTextField-root {
            align-items: end;
          }

          .MuiFormHelperText-root {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }

      .modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}
